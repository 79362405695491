import React from 'react';

import Layout from '@/components/others/Layout';
import FirstView from '@/components/common/section/FirstView';
import ExLinks from '@/components/exLink/section/ExLinks';
import BottomMenu from '@/components/common/section/BottomMenu';
import StaticSEO from '@/components/common/section/StaticSEO';

const ExLink: React.VFC = () => (
  <Layout>
    <StaticSEO
      titleData="外部リンク"
      descriptionData="鈴木弘基税理士事務所に関連する外部リンクはこちらのページにまとめています。"
      OgImageData="ExLink"
    />
    <FirstView pageTitle="外部リンク" fileName="ExLink" />
    <ExLinks />
    <BottomMenu />
  </Layout>
);

export default ExLink;
