import React from 'react';

import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

type ExLinkCardProps = {
  title: string;
  link: string;
  content: string;
};

const useStyles = makeStyles({
  exLinkPaper: {
    padding: `calc(1rem + 1vw)`,
  },
  linkStyles: {
    textDecoration: `none`,
    color: `#000000`,
  },
  linkTitle: {
    margin: `1rem auto`,
  },
  linkDesc: {
    margin: `1rem auto`,
  },
});

const ExLinkCard: React.VFC<ExLinkCardProps> = ({ title, link, content }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.exLinkPaper}>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.linkStyles}
      >
        <Typography variant="h4" className={classes.linkTitle}>
          {title}
        </Typography>
      </a>
      <Typography className={classes.linkDesc}>{content}</Typography>
    </Paper>
  );
};

export default ExLinkCard;
